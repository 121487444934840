<template>
  <!-- 计费方案 - 车型计费方案 -->
  <div class="ParkingInformation">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="计费方案：">
        <el-input maxlength="20" v-model="queryForm.scheme_name" @keyup.enter.native="onSubmit()"
          @input="(e) => (queryForm.scheme_name = validSpace(e))" placeholder="请输入计费方案"></el-input>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="车型计费方案">
      <template slot="heard-right">
        <div>
          <lbButton type="err" icon="shanchu1" @click="batchDel"
            v-if='isShowBtn("45d3a59b5d464f19a7262768") || isShowBtn("64fc0de613564a159cf70b56")'>删除</lbButton>
          <lbButton icon="xinzeng" @click="showAddModelProject = true"
            v-if='this.getUserInfo.user_name != "admin" && (isShowBtn("8d31c1cea85d4845bd637423") || isShowBtn("1b89cc27d2bd4c7685aaae5f"))'>
            新增</lbButton>
        </div>
      </template>
      <div class="box-card" style="text-align: left;">
        <!-- 主要内容 -->
        <!-- 表格 -->
        <!-- 使用插件生产表格 -->
        <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
          @size-change="sizeChange" @current-change="currentChange">
          <template slot-scope="{row}" slot="scheme_type">
            {{ getWayName("SchemeType", row.scheme_type) }}
          </template>
          <template slot-scope="{row}" slot="scheme_details">
            <div v-html="row.scheme_details.replace(/\r\n|\n|\r/g, '<br/>')"></div>
          </template>
          <template slot-scope="{row}" slot="parking">
            <el-link type="primary" target="_blank" v-if="row.parking.length > 0" @click="openParking(row)">{{
              row.parking.length }}</el-link>
            <span v-else>{{ row.parking.length }}</span>
          </template>
          <template slot-scope="{row}" slot="create_time">
            {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
          </template>
          <template slot-scope="{row}" slot="menu">
            <lbButton type="warning" icon="bianji" hint="编辑" @click="batchEdit(row)"
              v-if='isShowBtn("8ba17e23e521454cbc1c9d48") || isShowBtn("5fcca16913ff48ad87ccb4a2")'>编辑</lbButton>
          </template>
        </avue-crud> -->
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
          <el-table-column prop="scheme_name" label="计费方案"></el-table-column>
          <el-table-column label="方案类型">
            <template slot-scope="scope">
              {{ getWayName("SchemeType", scope.row.scheme_type) }}
            </template>
          </el-table-column>
          <el-table-column label="方案详情" min-width="220">
            <template slot-scope="scope">
              <div v-html="scope.row.scheme_details.replace(/\r\n|\n|\r/g, '<br/>')"></div>
            </template>
          </el-table-column>
          <el-table-column label="已配置" width="80">
            <template slot-scope="scope">
              <el-link type="primary" target="_blank" v-if="scope.row.parking.length > 0"
                @click="openParking(scope.row)">{{ scope.row.parking.length }}</el-link>
              <span v-else>{{ scope.row.parking.length }}</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间">
            <template slot-scope="scope">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <lbButton type="warning" icon="bianji" hint="编辑" @click="batchEdit(scope.row)"
                v-if='isShowBtn("8ba17e23e521454cbc1c9d48") || isShowBtn("5fcca16913ff48ad87ccb4a2")'>编辑</lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total" >
        </el-pagination>
      </div>
    </le-card>
    <lebo-dialog append-to-body title="已配置停车场" :isShow="isConfigParkingShow" width="30%" :footerSlot="true"
      @close="isConfigParkingShow = false" closeOnClickModal>
      <el-table :data="configParkingList" style="width: 100%" border stripe height="460">
        <el-table-column header-align="center" align="center" type="index" label="序号" width="100">
        </el-table-column>
        <el-table-column header-align="left" prop="parking_name" label="停车场">
        </el-table-column>
      </el-table>
    </lebo-dialog>
    <!-- 新增编辑计费方案 -->
    <lebo-dialog :isShow="showAddModelProject" width="42%" title="新增计费方案" :footerSlot="true"
      @close="showAddModelProject = false">
      <addModelProject @closeDialog="closeModelProject" v-if="showAddModelProject"></addModelProject>
    </lebo-dialog>
    <lebo-dialog :isShow="showEditModelProject" width="42%" title="编辑计费方案" :footerSlot="true"
      @close="showEditModelProject = false">
      <addModelProject @closeDialog="closeModelProject" v-if="showEditModelProject" :time_id="dialogTimeId">
      </addModelProject>
    </lebo-dialog>
  </div>
</template>

<script>
import { getMondelCardList, delTimeOrCount, getTimeCountSelectList, addModel, editModel } from '@/api/billingProject'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import addModelProject from './components/addModelProject/index.vue'
export default {
  components: { addModelProject },
  data () {
    return {
      // 查询表单
      queryForm: {
        scheme_name: '',
        PageSize: 10,
        PageIndex: 1
      },
      total: 0,
      // 表格数据
      tableData: [],
      // 选中的表格数据
      multipleSelection: [],
      isConfigParkingShow: false,
      configParkingList: [],
      showAddModelProject: false, // 弹框展示
      showEditModelProject: false,
      dialogTimeId: '', // 车型id
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '计费方案',
            prop: 'scheme_name'
          }, {
            label: '方案类型',
            prop: 'scheme_type',
            slot: true
          }, {
            label: '方案详情',
            prop: 'scheme_details',
            width: 350,
            slot: true
          }, {
            label: '已配置',
            prop: 'parking',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
    this.fnGetMondelCardList()
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
    this.carModelList = this.$store.getters.getOptions('ColorType')
    this.carModelList2 = this.$store.getters.getOptions('ColorType')
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  computed: {
    ...mapState(['parkingList', 'queryObj', 'queryStatus']),
    ...mapState('menuList', ['isRoutW']),
    ...mapGetters(['getOptions', 'getWayName', 'getUserInfo'])
  },
  methods: {
    ...mapActions(['requestPorkingList', 'requestFindList']),
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    // 查询车型计费方案列表
    async fnGetMondelCardList () {
      const res = await getMondelCardList(this.queryForm)
      this.total = res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      this.tableData = res.Code === 200 && res.Data ? res.Data.DataList : []
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetMondelCardList()
    },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetMondelCardList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetMondelCardList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      // console.log(`当前页: ${val}`);
      this.fnGetMondelCardList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetMondelCardList()
    // },
    // 批量删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除的计费方案, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // this.$msg.success('删除成功!');
          var obj = {}
          var arr = []
          for (var i = 0; i < this.multipleSelection.length; i++) {
            arr.push(this.multipleSelection[i]._id)
            if (arr.length === this.multipleSelection.length) {
              obj._ids = arr
              this.fnDelTimeOrCount(obj)
            }
          }
        }).catch(() => {
          this.$msg.info('已取消删除')
        })
      } else {
        this.$msg.warning('请选择要删除的计费方案！')
      }
    },
    // 删除计费方案请求
    async fnDelTimeOrCount (obj) {
      const res = await delTimeOrCount(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetMondelCardList()
    },
    batchEdit (row) {
      this.dialogTimeId = row._id
      this.showEditModelProject = true
      // console.log(row);
      // this.$router.push({
      //   path: this.isRoutW ? '/routw-add-model-project' : '/add-model-project',
      //   query: {
      //     id: row._id
      //   }
      // })
    },
    // 查看已配置停车场
    openParking (item) {
      this.isConfigParkingShow = true
      this.configParkingList = item.parking
      // console.log(this.configParkingList);
    },
    // 关闭弹框刷新
    closeModelProject () {
      this.showAddModelProject = false
      this.showEditModelProject = false
      this.fnGetMondelCardList()
    }
  }
}
</script>

<style scoped lang="less">
.demo-ruleForm {
  text-align: left;

  .el-textarea {
    width: 450px;
  }

  .el-input {
    width: 450px;
    margin-right: 10px;
  }

  // .quota_box {
  //     display: flex;
  // }
}

.ParkingInformation {
  margin-top: 20px;
}
</style>
