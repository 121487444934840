<template>
  <!-- 计费方案 - 新增车型计费方案 -->
  <div class="box-card" style="text-align: left;">
    <!-- 主要内容 -->
    <div>
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px" class="demo-ruleForm">
        <el-form-item label="方案名称：" prop="scheme_name">
          <el-input v-model="addForm.scheme_name" maxlength="20" @input="(e) => (addForm.scheme_name = validSpace(e))"
            placeholder="请输入方案名称"></el-input>
        </el-form-item>
        <el-form-item label="方案详情：" prop="scheme_details">
          <el-input type="textarea" maxlength="300" v-model="addForm.scheme_details" :rows="5" placeholder="请输入方案详情"
            @blur="addForm.scheme_details = ((addForm.scheme_details.replace(/\r\n|\n|\r/g, '<br/>')).replace(/\s/g, '')).replace(/<br\/>/g, '\n')"></el-input>
        </el-form-item>
        <el-form-item label="车牌类型：" class="time-frame" prop="car_model_details">
          <div v-for="(item, index) in addForm.car_model_details" :key="index">
            <div style="margin-bottom:20px;display: flex;align-items: center;width: 100%;">
              <el-form-item :prop="'car_model_details[' + index + '].name'"
                :rules="[{ required: true, message: '请选择车牌类型', trigger: 'blur' }]" style="margin-right:10px;" class="car_model_details_select">
                <el-select popper-class="my-select" v-model="item.name" placeholder="请选择车牌类型" @change="changeCarModel" class="selectInput"
                  clearable>
                  <el-option v-for="(model, j) in carModelList" :key="j" :label="model.name"
                    :value="model.name"></el-option>
                </el-select>
              </el-form-item>
              <span style="margin: 0 10px;min-width: 70px;">计费方案：</span>
              <el-form-item :prop="'car_model_details[' + index + '].cost_scheme_id'"
                :rules="[{ required: true, message: '请选择计费方案', trigger: 'blur' }]" class="car_model_details_select">
                <el-select popper-class="my-select" v-model="item.cost_scheme_id" placeholder="请选择计费方案" class="selectInput">
                  <el-option v-for="(item, i) in timeCountSelectList" :key="i" :label="item.scheme_name"
                    :value="item._id"></el-option>
                </el-select>
              </el-form-item>
              <!-- -->
              <div class="buttomIcon">
                <el-button class="buttonCicle"  icon="el-icon-plus" size="mini" circle
                v-if="index < carModelList2.length - 1" @click="addBillingTime(index)"></el-button>
              <el-button class="buttonCicle"  icon="el-icon-minus" size="mini" circle
                v-if="index > 0" @click="delBillingTime(item, index)"></el-button>
              </div>
            </div>
            <!-- <div style="display: inline-block;margin-bottom:10px;" v-else>
                                  之后每 <el-input style="width:120px;margin: 0 15px;"></el-input> 分钟，收费
                                      <el-input style="width:120px;margin: 0 15px;"></el-input> 元
                                  </div> -->
          </div>
        </el-form-item>
      </el-form>
    </div>
    <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm()" v-preventReClick>返 回</lbButton> -->
    <lbButton type="default" fill icon="confirm" class="checkButton" @click="submitAddForm()" v-preventReClick>保 存
    </lbButton>
  </div>
</template>

<script>
import { getDetailsList, getMonthCardView, getTimeCountSelectList, addModel, editModel } from '@/api/billingProject'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
export default {
  props: { time_id: { type: String } },
  data () {
    return {
      title: '',
      // 新增/编辑停车场表单
      addForm: {
        _id: '',
        scheme_name: '',
        scheme_details: '',
        car_model_details: [
          {
            value: null,
            name: '',
            cost_scheme_id: ''
          }
        ]
      },
      // 新增/编辑停车场表单验证
      addFormRules: {
        scheme_name: [
          { required: true, message: '请输入方案名称', trigger: 'blur' }
        ],
        scheme_details: [
          { required: true, message: '请输入方案详情', trigger: 'blur' }
        ],
        car_model_details: [
          { type: 'array', required: true, trigger: 'blur' }
        ]
      },
      // // 车牌类型列表：
      carModelList: [],
      // // 车牌类型列表2：
      carModelList2: [],
      // 计时计次方案下拉列
      timeCountSelectList: []
    }
  },
  created () {
    this.fngetTimeCountSelectList()
    if (this.time_id) {
      this.title = '编辑车型计费方案'
      this.fngetDetailsList()
    } else {
      this.title = '新增车型计费方案'
    }
  },
  mounted () {
    this.carModelList = this.$store.getters.getOptions('ColorType')
    this.carModelList2 = this.$store.getters.getOptions('ColorType')
  },
  computed: {
    ...mapState(['parkingList']),
    ...mapState('menuList', ['isRoutW']),
    ...mapGetters(['getOptions', 'getWayName'])
  },
  methods: {
    ...mapActions(['requestPorkingList', 'requestFindList']),
    ...mapMutations(['setQueryStatus']),
    goBack () {
      this.setQueryStatus(2)
      this.$router.go(-1)
    },
    // 添加计费时段
    addBillingTime (index) {
      // console.log(index);
      if (this.addForm.car_model_details.length < this.carModelList2.length) {
        var timer = {
          value: null,
          name: '',
          cost_scheme_id: ''
        }
        this.addForm.car_model_details.splice(index + 1, 0, timer)
      } else {

      }
    },
    // 删除计费时段
    delBillingTime (item, index) {
      // console.log(item, index);
      if (item.name) {
        var arr = this.carModelList2.filter(i => {
          return i.name === item.name
        })
        this.carModelList = this.carModelList.concat(arr)
      }
      this.addForm.car_model_details.splice(index, 1)
    },
    // 当车牌类型下拉框改变时
    changeCarModel (val) {
      // console.log(val);
      var list = this.addForm.car_model_details
      this.carModelList = this.carModelList2.concat()
      for (var i = 0; i < list.length; i++) {
        this.carModelList = this.carModelList.filter(item => {
          if (item.name === list[i].name) {
            list[i].value = item.value
          }
          return item.name !== list[i].name
        })
      }
    },
    // 展示编辑计费方案对话框
    async fngetDetailsList () {
      const res = await getDetailsList({
        time_id: this.time_id
      })
      if (res && res.Code === 200) {
        // console.log(res.Data);
        var row = res.Data
        // console.log(row);
        this.addForm._id = row._id
        this.addForm.scheme_name = row.scheme_name
        this.addForm.scheme_details = row.scheme_details
        var oldArr = row.car_model_details
        var arr = []
        for (var i = 0; i < oldArr.length; i++) {
          this.carModelList = this.carModelList.filter(item => {
            if (item.value === oldArr[i].color_type) {
              arr.push({
                value: item.value,
                name: item.name,
                cost_scheme_id: oldArr[i].cost_scheme_id
              })
            }
            return item.value !== oldArr[i].color_type
          })
        }
        this.addForm.car_model_details = arr
      }
    },
    //  计时计次方案下拉列
    async fngetTimeCountSelectList () {
      const res = await getTimeCountSelectList()
      // console.log(res);
      this.timeCountSelectList = res && res.Code === 200 ? res.Data : []
    },
    // 新增/编辑
    submitAddForm () {
      // 对整个表单进行校验
      this.$refs.addFormRef.validate((valid) => {
        // 成功
        if (valid) {
          var obj = {}
          var oldArr = this.addForm.car_model_details
          var arr = []
          for (var i = 0; i < oldArr.length; i++) {
            arr.push({
              color_type: oldArr[i].value,
              cost_scheme_id: oldArr[i].cost_scheme_id
            })
          }
          if (this.title === '新增车型计费方案') {
            obj = {
              scheme_name: this.addForm.scheme_name,
              scheme_details: this.addForm.scheme_details,
              car_model_details: arr
            }
            // console.log(obj);
            this.fnAddModel(obj)
          } else {
            obj = {
              _id: this.addForm._id,
              scheme_name: this.addForm.scheme_name,
              scheme_details: this.addForm.scheme_details,
              car_model_details: arr
            }
            this.fneditModel(obj)
          }
        } else {
          return false
        }
      })
    },
    // 新增月租卡计费方案接口
    async fnAddModel (obj) {
      const res = await addModel(obj)
      // console.log(res);
      this.isBatchAddDialogVisible = false
      if (res && res.Code === 200) {
        // this.$router.go(-1)
        this.$emit('closeDialog', false)
      }
    },
    // 编辑月租卡计费方案
    async fneditModel (obj) {
      const res = await editModel(obj)
      // console.log(res);
      this.isBatchAddDialogVisible = false
      if (res && res.Code === 200) {
        // this.$router.go(-1)
        // this.goBack()
        this.$emit('closeDialog', false)
      }
    },
    defaultForm () {
      this.$emit('closeDialog', false)
    }
  }
}
</script>

<style scoped lang="less">
.demo-ruleForm {
  text-align: left;
  overflow: auto;
  .el-textarea {
    width: 81%;
  }

  .el-input {
    width: 81%;
    margin-right: 10px;
  }

  // .quota_box {
  //     display: flex;
  // }
}
.time-frame{
  width: 100%;
  .car_model_details_select{
    width: 31.5%;
    .selectInput{
    width: 100%;
  }
  }
  .buttomIcon{
    width: 100px;
    .buttonCicle{
      margin-left:10px;padding:8px!important;
    }
  }
}
.box-card {
  position: relative;
  padding-bottom: 30px;

  .checkButton {
    position: absolute;
    right: 0px;
  }

  .checkButton2 {
    position: absolute;
    right: 100px;
  }
}
</style>
